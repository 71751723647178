import React from 'react';
import './custom.css';

const App: React.FC = () => {
  return (
    <p>
      Boiled brocolli is healthy 
    </p>
  );
};

export default App;